.add-article {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.add-art-input-panel{
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.add-art-input-panel input,
.add-art-input-panel textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    min-width: fit-content;
    background-color: #f9f9f9;
}

.art-add-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-left: 20px;
}

.add-art-preview {
    flex: 1;
}

