.gl-back-button {
    outline: solid 1px var(--orange);
    color: var(--orange);
    text-decoration: none;
    padding: 0px 38px 0px 32px;
    line-height: 3rem;
    font-size: 1rem;
    text-transform: uppercase;
    width: fit-content;
}

.game-layout {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.gl-banner {
    flex-grow: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    margin: 30px 0;
}

.gl-banner-logo {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gl-banner-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.gl-github {
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    filter: drop-shadow(0 2px 1px black);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.gl-github>div {
    height: fit-content;
    white-space: nowrap;
}

.gl-play {
    text-transform: uppercase;
    background: var(--orange);
    text-decoration: none;
    padding: 12px 32px;
    color: black;
}

.gl-info {
    display: flex;
    gap: 20px;
}

.gl-description {
    flex: 2
}

.gl-description-head::before {
    content: "• "
}

.gl-description-content {
    text-align: justify;
    text-justify: inter-word;
}

.gl-overview-content {
    color: var(--orange);
    text-align: justify;
    text-justify: inter-word;
}

.gl-info>.gip-panel {
    max-width: 600px;
}

.gl-banner-logo>img {
    filter: drop-shadow(0 2px 1px black);
}

.gl-banner-side {
    flex-wrap: nowrap;
}


@media (max-width: 1600px) {
    .gl-info {
        flex-direction: column-reverse;
    }

    .gl-info>.gip-panel {
        max-width: 100%;
        width: 100%;
    }

    .gl-banner-side {
        margin: 0 20px
    }

}

@media (max-width: 800px) {
    .gl-banner {
        background: #0000001d;
        flex-direction: column;
        background-position: center;
        background-size: cover;
    }

    .gl-banner-logo {
        flex: 1;
    }

    .gl-banner-side {
        flex-direction: column-reverse;
        min-height: 130px;
    }

    .gl-github {
        font-size: 1.2rem;
    }

    .gl-play {
        font-size: 1.2rem;
    }

    .gl-info {
        flex-direction: column-reverse;
        gap: 10px;
    }

    .gl-description {
        flex: 1;
    }
}
