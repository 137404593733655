.games {
    display: flex;
    gap: 60px;
    height: 100%;
}

/* verical*/
@media (orientation: portrait) {
    .games {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 800px) {
    .games {
        flex-direction: column;
        gap: 10px;
    }
}
