.blog-section {
    max-width: min(85%, 1557px);
    flex-grow: 1;
    margin-right: auto;
    margin-left: auto;
}

.blog {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.blog>.category-selector-container {
    margin: 1rem 0;
}

.page-selector-container {
    text-align: center;
    margin: 3rem 0;
}

.page-selector {
    font-size: 1.2rem;
    border: none;
    text-align: center;
    text-decoration: none;
    background: transparent;
    color: var(--white-op);
}

.page-selector::before {
    content: "[ ";
    font-size: 1.4rem;
}

.page-selector::after {
    content: " ]";
    font-size: 1.4rem;
}

.page-selector:hover {
    color: var(--orange);
    cursor: pointer;
}

.page-selector.selected {
    background: transparent;
    color: var(--orange);
}

.blog-articles-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.thumbnail {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    width: 377px;
    place-self: center;
}

.thumbnail>img {
    aspect-ratio: 16/9;
    height: 212px;
    object-fit: cover;
}

.tn-title {
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.8);
}

.tn-title>.gip-tag {
    font-size: 1rem;
    margin-bottom: 10px;
}

.tn-title-text {
    font-size: 1.2rem;
    line-height: 1.2em;
    color: white;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: calc(1.2em * 2);
    /* 2 lines of text plus padding */
    box-sizing: border-box;
}

@media (max-width: 1910px) {
    .blog-articles-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .blog-section {
        width: 100%;
    }
}

@media (max-width: 1440px) {
    .blog-articles-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .blog-section {
        max-width: 100%;
        width: 100%;
    }
}

@media (max-width: 1240px) {
    .blog-articles-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .thumbnail:nth-child(odd) {
        justify-self: end;
    }

    .thumbnail:nth-child(even) {
        justify-self: start;
    }

}

@media (max-width: 800px) {
    .blog-articles-grid {
        display: flex;
        flex-direction: column;
    }

    .category-selector-options {
        width: calc(100vw - 20px);
        right: 10px;
    }

    .tn-title {
        padding: 20px 0;
    }
}
