.game-show {
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.gs-title {
    text-transform: uppercase;
}

.gs-selector-button {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none;
    line-height: 0;
}

.gs-head {
    display: flex;
    justify-content: space-between;
}

.gs-selector-list {
    display: flex;
    gap: 15px;
}

.gs-selector-list * {
    cursor: pointer;
}

.gs-selector-button {
    transition: opacity 0.2s;
    opacity: 50%;
}

.gs-selector-button.active > .gs-selector-frame {
    opacity: 100%;
}

.gs-selector-button.active {
    opacity: 100%;
}

.gs-selector-frame {
    --bw: 3px;
    position: absolute;
    inset: var(--bw);
    outline: var(--bw) solid var(--orange);
    opacity: 0%;
    transition: opacity 0.2s;
}

.gs-slide {
    transition: background-image 0.5s;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1;
    max-height: 100%;
}

.gs-slide-name {
    font-size: 1.9rem;
    text-transform: uppercase;
}

.gs-slide-description {
    font-size: 1.4rem;
    line-height: 1.5;
    text-justify: inter-word;
    text-align: justify;
}

.gs-slide-overlay {
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    background-color: rgba(0,0,0,0.9);
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.gs-slide-button {
    line-height: 40px;
    text-decoration: none;
    background: var(--orange);
    padding: 5px 40px;
    color: black;
    width: fit-content;
    text-transform: uppercase;
    font-size: 1.4rem;
}

@media (max-width: 1140px) {
    .game-show {
        gap: 20px;
    }

    .gs-slide {
        display: flex;
        flex-direction: column-reverse;
    }

    .gs-slide-overlay {
        margin-top: 200px;
        width: 100%;
        height: auto;
        top: 50%;
        gap: 5px;
        padding: 10px;
    }

    .gs-head {
        display: flex;
        flex-direction: column;
    }

    .gs-title {
        font-size: 1rem;
    }

    .gs-slide-name {
        font-size: 1.4rem;
    }

    .gs-slide-description {
        font-size: 0.8rem;
    }

    .gs-slide-button {
        font-size: 1rem;
        line-height: 25px;
        padding: 5px 20px;
        margin-top: 10px;
    }

}
