header {
    color: white;
    margin-bottom: 20px;
}

header>hr {
    margin-bottom: 20px;
}

.header-minters {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    padding: 10px;
    padding-bottom: 0;
}

.header-logo {
    height: 50px;
}

.header-button::before {
    content: "[ ";
}

.header-button::after {
    content: " ]";
}

.header-button {
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    color: var(--white-op);
    font-size: 1.4rem;
    transition: color 0.2s;
}

.header-button.active {
    color: var(--orange);
    background-color: #FFA5001A;
}

.header-button:hover:not(.active) {
    color: white;
}

.navbar {
    display: flex;
    flex-direction: row;
}

.header-links {
    display: flex;
    gap: 10px;
}

.header-spacer-horizontal {
    flex-grow: 1;
}

.header-socials {
    display: flex;
    gap: 10px;
}

.header-socials>a {
    height: 30px;
}

.header-socials>a>img {
    height: 100%;
}

.header-list-button {
    visibility: hidden;
    position: relative;
    background: transparent;
    border: none;
    transition: color 0.2s;
    width: 28px;
    height: 28px;
    padding: 0;
    margin: 0;

}

.header-list-button>* {
    display: none;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    padding: 2px;
}

.header-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: var(--black);
    width: 100%;
    top: 100%;
}

.header-media-spacer {
    display: none;
}

@media (max-width: 800px) {
    header {
        margin-bottom: 10px;
        position: relative;
    }

    .header-minters {
        font-size: 1.5rem;
    }

    .header-button {
        font-size: 2rem;
        display: flex;
        gap: 5px;
    }

    .header-socials {
        justify-content: center;
        padding-bottom: 10px;
        margin: 15px 0;
        gap: 30px;
    }

    .header-socials>a {
        height: 40px;
    }

    .header-socials>a>img {
        height: 100%;
        width: auto;
    }

    .header-list-button>* {
        display: block;
    }

    .header-list-button {
        visibility: visible;
    }

    .navbar {
        position: absolute;
        flex-direction: column;
        top: 100%;
        background-color: #000;
        width: 100%;
        z-index: 100;
    }

    .header-links {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .header-spacer-horizontal {
        flex-grow: 1;
        border-bottom: 1px solid var(--white-op);
        margin: 10px 0;
    }

    .header-media-spacer {
        display: inline-block;
        flex-grow: 1;
    }

}
