:root {
    --gray: #FFFFFF;
    --gray-dark: #4D4D4D;
    --orange: #FFA500;
    --white-op: rgba(255, 255, 255, 0.4);
}

mark.orange {
    background: none;
    color: var(--orange);
}

hr {
    opacity: 40%;
}

@font-face {
    font-family: "BerkelyMono";
    src:
        url('./fonts/web/BerkeleyMono-Regular.woff2') format('woff2'),
        url('./fonts/web/BerkeleyMono-Regular.woff') format('woff'),
        url('./fonts/ttf/BerkeleyMono-Regular.ttf') format('truetype'),
        url('./fonts/otf/BerkeleyMono-Regular.otf') format('opentype'),
        local('Courier New');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    color: white;
    margin: 0 2vw;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: black;
}

body,
html,
input,
textarea,
button {
    font-family: "BerkelyMono", -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
