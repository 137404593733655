.category-selector-container {
    display: flex;
    justify-content: end;
    margin-top: 1rem;
}

.category-selector {
    position: relative;
}

.category-selector-title {
    font-size: 2rem;
}

.category-selector-selected {
    color: var(--orange);
    background: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    word-spacing: -0.7rem;
}

.category-selector-selected>img {
    display: inline-block;
    height: 0.7rem;
    margin-bottom: 0.2rem;
}

.category-selector-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    border-left: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-dark);
    right: 0;
}

.category-selector-option {
    color: white;
    background: black;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    text-align: left;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    white-space: nowrap;
}

@media (max-width: 800px) {
    .category-selector-container {
        margin-bottom: 1rem;
    }

    .category-selector-options {
        width: calc(100vw - 20px);
        right: 10px;
    }

    .category-selector-title {
        font-size: 1rem;
    }

    .category-selector-selected {
        font-size: 1rem;
        word-spacing: -0.3rem;
    }

    .category-selector-selected>img {
        height: 0.5rem;
        margin-bottom: 0.1rem;
    }
}
