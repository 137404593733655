.login-panel-form {
    display: flex;
    flex-direction: column;
    width: 20rem;
}

.login-panel-form>input {
    margin-bottom: 1rem;
    background: transparent;
    border: 1px white solid;
    padding: 0.5rem;
    color: white;
}

.login-panel-form>input:focus {
    border: 1px var(--orange) solid;
}

.login-panel-form-username:focus 
.login-panel-form-passwd:focus {
    border: 1px var(--orange) solid;
}

.login-panel-submit {
    background: var(--orange);
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    text-transform: uppercase;
}
