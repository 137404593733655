.about {
    flex: 1;
}

.about-content {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    gap: 30px;
}

.about-subsection-title {
    text-transform: uppercase;
    color: var(--orange);
}

.about-content-mission {
    min-width: 310px;
    flex: 1;
}

.about-content-mission>h4 {
    text-transform: uppercase;
}

.about-description {
    padding-right: 10px;
    flex: 3;
    min-width: 790px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
}

.contactus {
    flex: 1;
    max-width: 400px;
}

.contactus>h4 {
    text-transform: uppercase;
}

@media (max-width: 1674px) {
    .about-content {
        flex-direction: column;
    }

    .about-content>.horizontal {
        display: none;
    }

    .about-content-mission {
        margin-right: 0;
        min-width: 0;
        flex: none;
    }

    .about-description {
        margin: 0;
        min-width: 0;
        max-height:none;
        flex: none;
    }

    .contactus {
        margin: 0;
        margin-left: auto;
        margin-right: auto;
    }
}
