.art-page {
    display: flex;
    flex-direction: row;
}

.art-spacer {
    flex-grow: 1;
}

.art-container {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    max-width: 1200px;
}

.art-banner {
    width: 100%;
    max-height: 500px;
    aspect-ratio: 16/9;
}

.art-header {
    display: flex;
    flex-wrap: wrap;
}

.art-title {
    font-size: 2rem;
    padding: 10px;
    padding-bottom: 0;
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 60px;
}

.art-title>.gip-tag {
    display: inline-block;
    margin-right: 20px;
    height: 2rem;
    font-size: 1.3rem;
    padding: 3px 5px;
    box-sizing: border-box;
}

.art-section {
    margin-bottom: 30px;
}

.art-section-title {
    font-size: 1.5rem;
    padding: 10px;
    text-transform: capitalize;
    color: var(--orange);
}

.art-section-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    text-justify: inter-word;
}
