.legal {
    flex: 1;
}

.legal-content {
    display: flex;
    flex: 1;
}

.legal-content-index {
    margin-right: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.legal-description {
    margin: 0 100px;
    padding-right: 10px;
    flex: 3;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
}

.legal-ref {
    text-decoration: none;
    color: var(--white-op);
    transition: color 0.2s;
    text-transform: uppercase;
    white-space: pre-wrap;
    font-size: 1.4rem;
}

.legal-ref::before {
    content: "[ ";
}

.legal-ref::after {
    content: " ]";
}

.legal-ref:hover {
    color: var(--orange);
}

.legal-ref:active {
    color: var(--orange);
}

@media (max-width: 800px) {
    .legal-content {
        flex-direction: column;
    }

    .legal-content-index {
        margin-right: 0;
        flex: none;
    }

    .legal-description {
        margin: 0 10px;
    }
}
