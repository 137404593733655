.section-header {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

hr.solid {
    width: 100%;
}

hr.horizontal.solid {
    width: 1px;
}

.section-header-content {
    display: flex;
    justify-content: space-between;
}

.section-header-content > h2 {
    text-transform: capitalize;
}


.section-header-content>img {
    height: 1.5rem;
    margin-top: auto;
    margin-bottom: auto;
}
