.blog-carusel {
    display: flex;
    position: relative;
    padding: 30px 0;
    flex: 1;
}

.bc-thumbnail-list {
    overflow: hidden;
}

.bc-thumbnail-wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 20px;
    transition: left 0.1s;
}

.bc-arrow {
    background: rgba(0, 0, 0, 0.8);
    outline: 0;
    border: 0;
    width: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    color: white;
    font-size: 40px;
    z-index: 10;
    cursor: pointer;
}

.bc-arrow.left {
    left: 0;
}

.bc-arrow.right {
    right: 0;
}

.bc-thumbnail {
    position: relative;
    aspect-ratio: 16/9;
    height: 212px;
}

.bc-tn-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 10px;
    background-color: rgba(0, 0, 0, 0.8);
}

.bc-tn-title-text {
    font-size: 1.2rem;
    line-height: 1.2em;
    color: white;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: calc(1.2em * 2);
    /* 2 lines of text plus padding */
    box-sizing: border-box;
}

.bc-tn-banner {
    width: 100%;
    height: 100%;
}

@media (max-width: 800px) {
    .bc-arrow {
        display: none;
    }

    .bc-thumbnail-list {
        overflow-x: scroll;
    }

}
