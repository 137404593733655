.team {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 0;
}

.team-row {
    display: flex;
    flex-wrap: nowrap;
    gap: 30px;
    justify-content: left;
    --gray: #4D4D4D;
}

.square-bracket-left {
    --width: 2px;
    width: 7px;
    height: 100%;
    border-left: var(--width) solid var(--gray);
    border-top: var(--width) solid var(--gray);
    border-bottom: var(--width) solid var(--gray);
    margin-right: 10px;
}

.square-bracket-right {
    --width: 2px;
    width: 7px;
    height: 100%;
    border-right: var(--width) solid var(--gray);
    border-top: var(--width) solid var(--gray);
    border-bottom: var(--width) solid var(--gray);
    margin-left: 10px;
}

.member {
    display: flex;
    justify-content: center;
    align-items: center;
}

.member-content {
    height: 100%;
    width: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
}

.member-name {
    text-transform: uppercase;
    font-size: 1.5rem;
}

.member-description {
    text-transform: uppercase;
    color: var(--orange);
    max-width: 13ch;
    line-height: 1.2;
    height: 2lh;
}

.member-x {
    height: 16px;
}

.member-x-img {
    height: 100%;
}

@media (max-width: 1910px) {
    .member-content {
        width: 100%;
    }
    .member {
        width: 50%;
    }
}

@media (max-width: 800px) {
    .team-row {
        flex-direction: column;
    }
    .member {
        width: 100%;
    }
    .member-content {
        width: 100%;
    }
}
