.home {
    display: grid;
    grid-template-columns: minmax(0, 6fr) minmax(0, 4fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    flex: 1;
    justify-items: stretch;
    align-items: stretch;
}

.home-description {
    font-size: 1.2rem;
}

.home-description > p {
    text-justify: inter-word;
    text-align: justify;
}

@media (max-width: 1910px) {
    .home {
        grid-template-columns: minmax(0, 1fr);
    }
}

@media (max-width: 1140px) {
    .home {
        display: flex;
        flex-direction: column;
    }
}
