:root {
    --tile-transition-time: 0.5s;
}

.gametile {
    flex: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    transition: flex var(--tile-transition-time);
    overflow: hidden;
}

.gametile:hover {
    flex: 2;
}

.gametile:hover .gametile-description,
.gametile:hover .gametile-button {
    opacity: 100%;
}
.gametile .gametile-description,
.gametile .gametile-button
{
    display: none;
}

.gametile:hover .gametile-description
{
    display: block;
}
.gametile:hover .gametile-button {
    display: inline;
}

.gametile-gradient {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 50px;
    width: 100%;
    height: 100%;
    background: rgb(2, 0, 36);
    background: -moz-linear-gradient(90deg, rgba(2, 0, 36, 0.6671043417366946) 0%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(2, 0, 36, 0.6671043417366946) 0%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(90deg, rgba(2, 0, 36, 0.6671043417366946) 0%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024", endColorstr="#ffffff", GradientType=1);
}

.gametile-button {
    text-transform: uppercase;
    border: none;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    color: black;
    padding: 20px 32px;
    background: var(--orange);
    line-height: 60px;
    width: 100%;
    height: fit-content;
    opacity: 0%;
    transition: opacity var(--tile-transition-time);
}

.gametile-description {
    font-size: 1.5rem;
    white-space: nowrap;
    opacity: 0%;
    transition: opacity var(--tile-transition-time);
    margin: 15px 0;
}

@media (orientation: portrait) {

    .gametile-description {
        font-size: 1rem;
        white-space: wrap;
    }

    .gametile-logo>img {
        max-height: 90px;
    }

}
