.contactus {
    margin-left: 50px;
}

.contactus>h3 {
    text-transform: uppercase;
}

.contactus>form {
    border: var(--white-op) solid 1px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 300px;
}

.contactus>form>input,
.contactus>form>textarea {
    outline: none;
    background: rgba(255,255,255,0.05);
    color: white;
    box-shadow: 0;
    border: 0;
    border-bottom: 1px solid var(--white-op);
    transition: border-bottom 0.2s;
    padding: 10px;
}

.contactus>form>textarea {
    height: 9.8rem;
}

.contactus>form>input:hover,
.contactus>form>textarea:hover {
    border-bottom: 1px solid var(--gray);
}

.contactus>form>input:focus,
.contactus>form>textarea:focus {
    border-bottom: 1px solid var(--orange);
}

.contactus>form>button {
    background: var(--orange);
    border: none;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
}

.form-dots {
    width: 50px;
    margin-left: calc(100% - 50px) ;
}
