.gip-panel>img {
    width: 70px;
    height: 70px;
}

.gip-panel {
    outline: solid 1px var(--white-op);
    box-sizing: border-box;
    padding: 30px;
    padding-bottom: 25px;
    gap: 20px;
    font-size: 1.4rem;
    height: fit-content;
}

.gip-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.gip-tag {
    background: rgba(255, 165, 0, 0.1);
    text-transform: uppercase;
    color: var(--orange);
    border-left: solid 1px var(--orange);
    border-right: solid 1px var(--orange);
    padding: 8px 10px;
    width: fit-content;
    font-size: 1.5rem;
}

.gip-dots {
    border-bottom: 2px dotted var(--white-op);
    margin: 0 10px;
    flex: 1;
    transform: translateY(-3px);
}

.gip-panel-info-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.gip-panel-info-value {
    color: var(--orange);
}

.gip-panel-info {
    display: flex;
    flex-direction: row;
}

.gip-panel-platform-link {
    color: var(--orange);
}

@media (max-width: 800px) {
    .gip-panel {
        padding: 20px;
        gap: 10px;
        font-size: 1.2rem;
    }

    .gip-tags {
        gap: 10px;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .gip-tag {
        padding: 5px 7px;
        font-size: 0.7rem;
    }

    .gip-dots {
        margin: 0 5px;
    }

    .gip-panel-info-list {
        gap: 3px;
    }
    .gip-panel-info {
        font-size: 0.8rem;
    }
}
